import { InboxOutlined } from '@ant-design/icons';
import React, { useContext } from 'react';
import { Upload, message } from 'antd';
import AWS from 'aws-sdk';
import { BoardContext } from 'context/BoardContextProvider';

function App() {
  const { zipTableData, fetchExcelUploadDev, credentials, s3BucketName, setIsAutoRefreshActive } =
    useContext(BoardContext);

  const uploadFile = async (file: any, onSuccess: any) => {
    const S3_BUCKET = credentials.s3UploadBucket;
    const REGION = 'us-east-1';

    AWS.config.update({
      accessKeyId: credentials.accessKeyId,
      secretAccessKey: credentials.secretAccessKey,
      sessionToken: credentials.sessionToken
    });

    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION
    });

    const params = {
      Bucket: S3_BUCKET,
      Key: file.name,
      Body: file
    };

    var upload = s3
      .putObject(params)
      .on('httpUploadProgress', (evt: any) => {
        // File uploading progress
      })
      .promise();

    await upload.then(
      (data: AWS.S3.PutObjectOutput) => {
        ///
      },
      (err: AWS.AWSError) => {
        console.error(err);
        // Handle error
        alert('Error uploading file.');
      }
    );

    onSuccess();
    fetchExcelUploadDev(s3BucketName);
    setIsAutoRefreshActive(true);
  };

  const props = {
    name: 'file',
    multiple: false,
    accept: '.zip', // Accept only ZIP files
    customRequest: ({ file, onSuccess, onError }: any) => {
      if (!file.name.toLowerCase().endsWith('.zip')) {
        onError('Invalid file type. Please upload a ZIP file.');
        return;
      }
      const fileNameWithoutExtension = file.name.substring(0, file.name.lastIndexOf('.')).toLowerCase();
      if (zipTableData.some((item: any) => item.name.toLowerCase() === fileNameWithoutExtension)) {
        onError('File with the same name already exists.');
      } else {
        uploadFile(file, onSuccess);
      }
    },
    onChange(info: any) {
      const { status } = info.file;
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e: any) {
      ////
    }
  };

  return (
    <div className="drag_and_drop_wrapper">
      <div className="drag_and_drop">
        <Upload.Dragger {...props} accept="*">
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload to <b>{credentials.s3UploadBucket}</b>
          </p>
          <p className="ant-upload-hint dragger_margin">Upload a ZIP file</p>
        </Upload.Dragger>
      </div>
    </div>
  );
}

export default App;
