import { FC, useState } from 'react';
import { Tooltip } from 'antd';
import useOuterClick from './customHooks/useOuterclick';
import React from 'react';

export const FilterBox: FC<any> = ({ filterData, currentFilter }) => {
  const [isFilterBoxOn, setIsFilterBoxOn] = useState<boolean>(false);
  const filterWrapperRef = useOuterClick<HTMLDivElement>((e) => {
    setIsFilterBoxOn(false); // Close the filter box when clicked outside
  });

  const toggleFilterBox = () => {
    setIsFilterBoxOn((prev) => !prev);
  };

  const whatNeedsToBeChecked = [
    { filterId: 'configuration-error-report.txt', filterName: 'configuration error reports' },
    { filterId: 'errors-report.xlsx', filterName: 'errors reports' },
    { filterId: 'classification-error-report.txt', filterName: 'classification error reports' },
    { filterId: 'unsupported-error-report.txt', filterName: 'unsupported error reported' }
  ];

  if (!filterData) {
    return null;
  }

  const handleFilterData = (item: any) => {
    filterData(item);
    setIsFilterBoxOn(false);
  };

  const filterBox = () => {
    return (
      <div className="filter_box">
        {whatNeedsToBeChecked.map((item: any, index: number) => {
          return (
            <div
              key={index}
              className={` ${currentFilter.filterId === item.filterId ? 'filter_item selected_filter' : 'filter_item'}`}
              onClick={() => handleFilterData(item)}
            >
              {item.filterName}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div style={{ width: '70px', position: 'relative' }} ref={filterWrapperRef}>
      <Tooltip title="Filter data">
        <button
          className={`download_button refresh_button_width ${currentFilter.filterId ? 'dark_blue_hover' : 'dark_blue'}`}
          style={{ width: '50px' }}
          onClick={toggleFilterBox}
        >
          <svg
            className={`${currentFilter.filterId && 'flip_data'}`}
            xmlns="http://www.w3.org/2000/svg"
            width="25px"
            height="25px"
            viewBox="0 0 24 24"
            fill="white"
          >
            <g id="style=stroke">
              <g id="filter-circle">
                <path
                  id="vector (Stroke)"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.75 17.5C7.75 17.0858 7.41421 16.75 7 16.75H2C1.58579 16.75 1.25 17.0858 1.25 17.5C1.25 17.9142 1.58579 18.25 2 18.25H7C7.41421 18.25 7.75 17.9142 7.75 17.5Z"
                  fill="#fff"
                />
                <path
                  id="vector (Stroke)_2"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M16.25 6.5C16.25 6.08579 16.5858 5.75 17 5.75H22C22.4142 5.75 22.75 6.08579 22.75 6.5C22.75 6.91421 22.4142 7.25 22 7.25H17C16.5858 7.25 16.25 6.91421 16.25 6.5Z"
                  fill="#fff"
                />
                <path
                  id="vector (Stroke)_3"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M22.75 17.5C22.75 17.0858 22.4142 16.75 22 16.75H13C12.5858 16.75 12.25 17.0858 12.25 17.5C12.25 17.9142 12.5858 18.25 13 18.25H22C22.4142 18.25 22.75 17.9142 22.75 17.5Z"
                  fill="#fff"
                />
                <path
                  id="vector (Stroke)_4"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M1.25 6.5C1.25 6.08579 1.58579 5.75 2 5.75H11C11.4142 5.75 11.75 6.08579 11.75 6.5C11.75 6.91421 11.4142 7.25 11 7.25H2C1.58579 7.25 1.25 6.91421 1.25 6.5Z"
                  fill="#fff"
                />
                <path
                  id="vector (Stroke)_5"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10 15.1499C11.2426 15.1499 12.25 16.1573 12.25 17.3999C12.25 18.6425 11.2426 19.6499 10 19.6499C8.75736 19.6499 7.75 18.6425 7.75 17.3999C7.75 16.1573 8.75736 15.1499 10 15.1499ZM13.75 17.3999C13.75 15.3288 12.0711 13.6499 10 13.6499C7.92893 13.6499 6.25 15.3288 6.25 17.3999C6.25 19.471 7.92893 21.1499 10 21.1499C12.0711 21.1499 13.75 19.471 13.75 17.3999Z"
                  fill="#fff"
                />
                <path
                  id="vector (Stroke)_6"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M14 4.1499C12.7574 4.1499 11.75 5.15726 11.75 6.3999C11.75 7.64254 12.7574 8.6499 14 8.6499C15.2426 8.6499 16.25 7.64254 16.25 6.3999C16.25 5.15726 15.2426 4.1499 14 4.1499ZM10.25 6.3999C10.25 4.32883 11.9289 2.6499 14 2.6499C16.0711 2.6499 17.75 4.32883 17.75 6.3999C17.75 8.47097 16.0711 10.1499 14 10.1499C11.9289 10.1499 10.25 8.47097 10.25 6.3999Z"
                  fill="#fff"
                />
              </g>
            </g>
          </svg>
        </button>
      </Tooltip>
      {isFilterBoxOn && <div className="filter_wrapper">{filterBox()}</div>}
    </div>
  );
};

export default FilterBox;
