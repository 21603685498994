import { useEffect, useRef } from 'react';

const useOuterClick = <T extends HTMLElement = HTMLElement>(callback: (event: MouseEvent) => void) => {
  const innerRef = useRef<T>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (innerRef.current && !innerRef.current.contains(event.target as Node)) {
        callback(event);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [callback]);

  return innerRef;
};

export default useOuterClick;
