import { useOidc } from '@axa-fr/react-oidc';
import { Button } from '@datum/react-components';
import React from 'react';

const POST_LOGIN_REDIRECT = window.location.origin + '/landing';

const LogoutPage = () => {
  const { login } = useOidc();

  localStorage.setItem('accessKeyId', '');
  localStorage.setItem('secretAccessKey', '');
  localStorage.setItem('sessionToken', '');

  localStorage.setItem('s3_upload_bucket', '');
  localStorage.setItem('s3_reports_bucket', '');
  localStorage.setItem('s3_oracle_payloads_bucket', '');

  return (
    <div style={{ maxWidth: '600px', margin: '10% auto' }}>
      <h1 style={{ fontSize: '30px', textTransform: 'uppercase' }}>Thank you!</h1>
      <p>
        Thank you for being a part of the <b>RapidETL</b> community. We look forward to serving you and meeting your
        needs in the future.
      </p>
      <br />
      <div className="button_right_position">
        <Button variant="primary" onClick={() => login(POST_LOGIN_REDIRECT)}>
          Go to Login page
        </Button>
      </div>
    </div>
  );
};

export default LogoutPage;
