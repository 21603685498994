import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tabs } from 'antd';
import ReportsTable from './ReportsTable';
import OracleTable from './OracleTable';
import DragAndDrop from './DragAndDrop';
import CountdownTimer from './CountdownTimer';
import { BoardContext } from 'context/BoardContextProvider';
import PieChart from './pieChart/PieChart';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import axios from 'axios';

const { TabPane } = Tabs;

const ReportsPage: React.FC = () => {
  const {
    fetchExcelUploadDev,
    fetchOraclePayloadsData,
    zipTableData,
    zipTableDataOracle,
    activeKey,
    setActiveKey,
    setS3BucketName,
    s3BucketName,
    credentials,
    isConnectedToAWS,
    checkAwsCredentials
  } = useContext(BoardContext);
  const navigate = useNavigate();
  const { accessToken } = useOidcAccessToken();

  useEffect(() => {
    document.title = 'RapidETLite';
    fetchExcelUploadDev(credentials.s3ReportsBucket);
    fetchOraclePayloadsData(credentials.s3OraclePayloadsBucket);
  }, []);

  // if (!localStorage.getItem('isSuccessfullyLogged')) {
  //   navigate('/');
  // }

  useEffect(() => {
    if (isConnectedToAWS) {
      fetchExcelUploadDev(credentials.s3ReportsBucket);
      // if (fetchExcelUploadDev(credentials.s3ReportsBucket)) {
      //   navigate('/reportsTable');
      // }
    }
  }, [isConnectedToAWS]);

  const fetchS3Access = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`
      };

      const response = await axios.post(
        'https://rapidetlite-webproxy.aws.datumsolutions.me/S3Access/get-temporary-s3-access',
        null,
        {
          headers: headers
        }
      );

      if (response.status === 200) {
        const { accessKeyId, secretAccessKey, sessionToken, bucketNames } = response.data;

        let s3_upload_bucket: string = bucketNames['s3_upload_bucket'] ?? '';
        let s3_reports_bucket: string = bucketNames['s3_reports_bucket'] ?? '';
        let s3_oracle_payloads_bucket: string = bucketNames['s3_oracle_payloads_bucket'] ?? '';

        localStorage.setItem('accessKeyId', accessKeyId);
        localStorage.setItem('secretAccessKey', secretAccessKey);
        localStorage.setItem('sessionToken', sessionToken);

        localStorage.setItem('s3_upload_bucket', s3_upload_bucket);
        localStorage.setItem('s3_reports_bucket', s3_reports_bucket);
        localStorage.setItem('s3_oracle_payloads_bucket', s3_oracle_payloads_bucket);

        checkAwsCredentials(accessKeyId, secretAccessKey, sessionToken, bucketNames);
      } else {
        console.error('Error:', response.status);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    fetchS3Access();
  }, []);

  const handleChange = (key: any) => {
    setActiveKey(key);
    if (key == '1') {
      setS3BucketName(credentials.s3ReportsBucket);
      if (!zipTableData.length) {
        fetchExcelUploadDev(credentials.s3ReportsBucket);
      }
    }

    if (key == '2') {
      setS3BucketName(credentials.s3OraclePayloadsBucket);
      if (!zipTableDataOracle.length) {
        fetchOraclePayloadsData(credentials.s3OraclePayloadsBucket);
      }
    }
  };

  return (
    <div className="home-page">
      {/* <div style={{ display: 'none' }}>
        <LoginPage />
      </div> */}
      <section className="header">
        <div className="bg-img"></div>
        <div className="container">
          <div className="drag-drop-area">
            <DragAndDrop />
          </div>
          {s3BucketName === credentials.s3ReportsBucket && (
            <div className="data-visualization">
              <PieChart />
            </div>
          )}
        </div>
      </section>

      <div className="position_relative">
        <CountdownTimer />
        <Tabs defaultActiveKey="1" activeKey={activeKey} onChange={handleChange}>
          <TabPane tab="VALIDATION REPORTS" key="1">
            <ReportsTable reportNameFromTab={credentials.s3ReportsBucket} />
          </TabPane>
          <TabPane tab="ORACLE PAYLOADS" key="2">
            <OracleTable reportNameFromTab={credentials.s3OraclePayloadsBucket} />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default ReportsPage;
