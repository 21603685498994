import { BoardContext } from 'context/BoardContextProvider';
import React from 'react';
import { useContext, useEffect, useState } from 'react';

const CountdownTimer = () => {
  const { s3BucketName, fetchExcelUploadDev } = useContext(BoardContext);
  const [remainingTime, setRemainingTime] = useState(1);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const handleRefresh = () => {
    fetchExcelUploadDev(s3BucketName);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setRemainingTime((prevTime: any) => {
        if (prevTime === 0) {
          setIsRefreshing(true);
          setTimeout(() => {
            setIsRefreshing(false);
            handleRefresh();
            setRemainingTime(60);
          }, 1000);
        }
        return prevTime === 0 ? prevTime : prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [handleRefresh]);

  return (
    <div className="countdown_timer_wrapper">
      {isRefreshing ? <div>Refreshing...</div> : <div>Refresh in {remainingTime}</div>}
    </div>
  );
};

export default CountdownTimer;
