import React, { useContext, useEffect, useRef, useState } from 'react';
import { Table, Checkbox, Button, Tooltip, Spin } from 'antd';
import { DownloadOutlined, SearchOutlined, ReloadOutlined } from '@ant-design/icons';
import { SortOrder } from 'antd/lib/table/interface';
import { Key } from 'antd/lib/table/interface';
import { BoardContext } from 'context/BoardContextProvider';
import { LoadingOutlined } from '@ant-design/icons';

const OracleTable: React.FC<any> = ({ reportNameFromTab }) => {
  const {
    zipTableDataOracle,
    credentials,
    downloadFileFromS3,
    setSelectedProject,
    fetchOraclePayloadsData,
    setZipTableDataOracle,
    activeKey,
    isDarkMode
  } = useContext(BoardContext);

  const [selectedCheckboxes, setSelectedCheckboxes] = useState<any[]>([]);
  const [filteredTableData, setFilteredTableData] = useState<any[]>(zipTableDataOracle);
  const [selectAll, setSelectAll] = useState(false);
  const inputRef = useRef<any>(null);

  useEffect(() => {
    setSelectedCheckboxes([]);
  }, [activeKey]);

  const handleSelectAll = () => {
    const selected = !selectAll;
    setSelectAll(selected);
    const allIndices = zipTableDataOracle.map((item: any, index: any) => item.name);

    if (selected) {
      setSelectedCheckboxes(allIndices);
    } else {
      setSelectedCheckboxes([]);
    }
  };

  const clearInputValue = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  const handleCheckboxChange = (
    selectedRowKeys: Key[],
    selectedRows: {
      name: string;
      type: string;
      lastModified: string;
      size: string | number;
      storageClass: string;
      key: number;
    }[],
    info: {
      type: 'checkbox' | 'radio';
      checked: boolean;
      selectedRowKeys: Key[];
      nativeEvent: MouseEvent;
    }
  ) => {
    const index = info.selectedRowKeys[0] as number;
    const selectedIndices = [...selectedCheckboxes];

    if (info.checked) {
      selectedIndices.push(index);
    } else {
      const indexToRemove = selectedIndices.indexOf(index);
      if (indexToRemove !== -1) {
        selectedIndices.splice(indexToRemove, 1);
      }
    }

    setSelectedCheckboxes(selectedIndices);
    setSelectAll(selectedIndices.length === zipTableDataOracle.length);
  };

  const redirectToSubTable = (item: any) => {
    setSelectedProject(item);
  };

  const filterTableData = (filterText: string) => {
    const filteredData = zipTableDataOracle.filter((item: any) =>
      item.name.toLowerCase().includes(filterText.toLowerCase())
    );

    setFilteredTableData(filteredData);
  };

  useEffect(() => {
    setFilteredTableData(zipTableDataOracle);
  }, [zipTableDataOracle]);

  const handleDownload = () => {
    if (!filteredTableData || !selectedCheckboxes || !filteredTableData.length || !selectedCheckboxes.length) {
      console.error('Error: filteredTableData or selectedCheckboxes is undefined or empty.');
      return;
    }

    const filesForDownload: string[] = selectedCheckboxes.reduce((acc: any, itemName: string) => {
      const file = filteredTableData.find((item) => item.name === itemName);
      if (file) {
        file.subFolders.forEach((subFolder: any) => {
          acc.push(`${subFolder.name}`);
        });
      } else {
        console.error(`Error: Item '${itemName}' not found in filteredTableData.`);
      }
      return acc;
    }, []);

    if (!filesForDownload.length) {
      console.error('Error: No valid files selected for download.');
      return;
    }

    downloadFileFromS3(credentials.s3OraclePayloadsBucket, filesForDownload, 'downloaded_files');
  };

  const handleDownloadItem = (item: any) => {
    let itemNames = item.subFolders.map((item: any) => {
      return item.name;
    });

    downloadFileFromS3(credentials.s3OraclePayloadsBucket, itemNames, item.name);
  };

  const handleRefresh = () => {
    clearInputValue();
    setZipTableDataOracle([]);
    fetchOraclePayloadsData(credentials.s3OraclePayloadsBucket);
  };

  const columns = [
    {
      title: <Checkbox checked={selectAll} onChange={handleSelectAll} />,
      dataIndex: 'checkbox',
      key: 'checkbox',
      render: (_: any, record: any) => (
        <Checkbox
          checked={selectedCheckboxes.includes(record.name)}
          onChange={(e) =>
            handleCheckboxChange([record.key], [record], {
              type: 'checkbox',
              checked: e.target.checked,
              selectedRowKeys: [record.key],
              nativeEvent: e.nativeEvent as MouseEvent
            })
          }
        />
      )
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a: any, b: any) => a.name.localeCompare(b.name), // Sorting by name
      render: (text: string, record: any) => <span onClick={() => redirectToSubTable(record)}>{text}</span>
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type'
    },
    {
      title: 'Last Modified',
      dataIndex: 'lastModified',
      key: 'lastModified',
      sorter: (a: any, b: any) => {
        const dateA = new Date(a.rawLastModified);
        const dateB = new Date(b.rawLastModified);

        if (dateA < dateB) {
          return -1;
        }
        if (dateA > dateB) {
          return 1;
        }
        return 0;
      },
      defaultSortOrder: 'descend' as SortOrder // Set default order to descending
    },
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size'
    },
    {
      title: 'Storage Class',
      dataIndex: 'storageClass',
      key: 'storageClass'
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      render: (_: any, record: any) => (
        <div className="center_download_horizontally">
          <Tooltip title="Download single file">
            <Button type="link" icon={<DownloadOutlined />} onClick={() => handleDownloadItem(record)} />
          </Tooltip>
        </div>
      )
    }
  ];

  const mappedData = filteredTableData.map((item, index) => ({
    key: item.name,
    ...item
  }));

  const paginationConfig: any = {
    pageSize: 20,
    position: ['bottomLeft', 'bottomRight'],
    showSizeChanger: false,
    showQuickJumper: false
  };

  const isDateOlderThan30Minutes = (dateString: string): boolean => {
    const date = new Date(dateString);
    const currentDate = new Date(
      Date.UTC(
        new Date().getUTCFullYear(),
        new Date().getUTCMonth(),
        new Date().getUTCDate(),
        new Date().getUTCHours(),
        new Date().getUTCMinutes(),
        new Date().getUTCSeconds(),
        new Date().getUTCMilliseconds()
      )
    );
    return !((currentDate.getTime() - date.getTime()) / (1000 * 60) > 30);
  };

  const rowClassName = (record: any, index: number): string => {
    if (isDateOlderThan30Minutes(record.rawLastModified)) {
      if (index % 2 === 0) {
        return isDarkMode ? 'older-than-30-minutes-dark_dm' : 'older-than-30-minutes-dark';
      } else {
        return isDarkMode ? 'older-than-30-minutes-light_dm' : 'older-than-30-minutes-light';
      }
    }
    return '';
  };

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <div className="table_wrapper">
      <div className="table_header">
        <div className="table_title_flex">{/* {isAutoRefreshActive && <div>Auto-refresh in 5 seconds...</div>} */}</div>
        <div className="filter-wrapper">
          <div className="zip_filter_holder">
            <input
              ref={inputRef}
              type="text"
              placeholder="Type to filter..."
              onChange={(e) => filterTableData(e.target.value)}
            />
            <button>
              <SearchOutlined />
            </button>
          </div>
          <Tooltip title={selectedCheckboxes.length > 0 ? 'Download multiple files' : 'No files selected'}>
            <button
              className={`${
                selectedCheckboxes.length > 0 ? '' : 'disabled_button'
              } download_button download_button_width`}
              onClick={handleDownload}
            >
              <DownloadOutlined />
              DOWNLOAD
            </button>
          </Tooltip>
          <Tooltip title="Download single folder">
            <button className="download_button refresh_button_width small_button_width" onClick={handleRefresh}>
              <ReloadOutlined />
            </button>
          </Tooltip>
        </div>
      </div>

      <section className="table-container">
        <Table
          columns={columns}
          dataSource={mappedData}
          pagination={paginationConfig}
          rowClassName={(record, index) => rowClassName(record, index)}
          locale={{
            emptyText: (
              <div className="spin_center">
                <Spin indicator={antIcon} />
              </div>
            )
          }}
        />
      </section>
    </div>
  );
};

export default OracleTable;
