import * as React from 'react';
import { Navigate, Route as ReactRouterRoute, Routes } from 'react-router-dom';

import NotFound from 'pages/NotFound';
import LogoutPage from 'pages/LogoutPage';
import Route from './SecureRoute';
import ReportsPage from 'componentsReports/ReportsPage';
import LoginPage from 'componentsReports/login/Login';

function AppRoutes(): JSX.Element {
  return (
    <Routes>
      <Route path="/" element={<Navigate replace to="/landing" />} />
      <Route path="/landing" element={<ReportsPage />} />
      {/* <Route path="/reportsTable" element={<ReportsPage />} /> */}

      <ReactRouterRoute path="/thank-you" element={<LogoutPage />} />
      <Route path="/" element={<Navigate to="/" />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export { AppRoutes };
