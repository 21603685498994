import { BoardContext } from 'context/BoardContextProvider';
import React, { useContext } from 'react';
import { Chart } from 'react-google-charts';

interface TableData {
  name: string;
  type: string;
}

const calculateOccurrences = (tableData: any[]): any[] => {
  const occurrencesMap: { [key: string]: number } = {};

  const whatNeedsToBeChecked = [
    'configuration-error-report.txt',
    'errors-report.xlsx',
    'classification-error-report.txt',
    'unsupported-error-report.txt'
  ];

  tableData.forEach(({ name, type }) => {
    const fileName: any = `${name}.${type}`;
    let found = true;

    whatNeedsToBeChecked.forEach((checkString: any) => {
      if (fileName.includes(checkString)) {
        occurrencesMap[checkString] = (occurrencesMap[checkString] || 0) + 1;
        found = true;
      }
    });

    if (!found) {
      occurrencesMap['other'] = (occurrencesMap['other'] || 0) + 1;
    }
  });

  const results: any[] = [['name', 'occurrence']];

  for (const [key, value] of Object.entries(occurrencesMap)) {
    results.push([key, value]);
  }

  return results;
};

const PieChart: React.FC<any> = () => {
  const { zipTableData } = useContext(BoardContext);

  const result = calculateOccurrences(zipTableData);

  let data: any = [['name', 'occurrence']];
  data = result;

  if (JSON.stringify(data) === JSON.stringify([['name', 'occurrence']])) {
    return <div></div>;
    // return (
    //   <div className="loader-container">
    //     <div className="loader"></div>
    //   </div>
    // );
  }

  const options = {
    title: '',
    backgroundColor: 'transparent',
    titleTextStyle: {
      color: 'white',
      bold: true
    },
    chartArea: {
      width: '100%',
      height: '100%'
    },
    // pieSliceText: 'label',
    legend: {
      position: 'left',
      width: '500px',
      alignment: 'center',
      _bottom: 0,
      get bottom() {
        return this._bottom;
      },
      set bottom(value) {
        this._bottom = value;
      },
      textStyle: {
        color: 'white',
        bold: true
      }
    }
  };

  return (
    <div className="pie_chart_wrapper">
      <Chart chartType="PieChart" data={data} options={options} width={'100%'} height={'400px'} />
    </div>
  );
};

export default PieChart;
