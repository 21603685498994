import React, { useContext, useEffect, useRef, useState } from 'react';
import { Table, Checkbox, Button, Tooltip, Spin } from 'antd';
import { DownloadOutlined, SearchOutlined, ReloadOutlined } from '@ant-design/icons';
import { Key } from 'antd/lib/table/interface';
import { SortOrder } from 'antd/lib/table/interface';
import FilterBox from './FilterBox';
import { BoardContext } from 'context/BoardContextProvider';
import { LoadingOutlined } from '@ant-design/icons';

const ReportsTable: React.FC<any> = ({ reportNameFromTab }) => {
  const {
    zipTableData,
    downloadFileFromS3,
    downloadOneFileFromS3,
    isAutoRefreshActive,
    credentials,
    setIsAutoRefreshActive,
    fetchExcelUploadDev,
    setZipTableData,
    activeKey,
    isDarkMode,
    isConnectedToAWS
  } = useContext(BoardContext);

  const [selectedCheckboxes, setSelectedCheckboxes] = useState<any[]>([]);
  const [filteredTableData, setFilteredTableData] = useState<any[]>(zipTableData);
  const [dataToRender, setDataToRender] = useState<any[]>(zipTableData);
  const [selectAll, setSelectAll] = useState(false);
  const [currentFilter, setCurrentFilter] = useState<any>({ filterId: '', filterName: '' });
  const [isInitialLoadDone, setIsInitialLoadDone] = useState<boolean>(false);
  const inputRef = useRef<any>(null);

  useEffect(() => {
    setFilteredTableData(zipTableData);
    setDataToRender(zipTableData);
  }, [zipTableData]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (isAutoRefreshActive) {
      timeoutId = setTimeout(() => {
        setIsAutoRefreshActive(false);
        fetchExcelUploadDev(reportNameFromTab);
      }, 20000);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isAutoRefreshActive]);

  useEffect(() => {
    setSelectedCheckboxes([]);
  }, [activeKey]);

  const handleSelectAll = () => {
    const selected = !selectAll;
    setSelectAll(selected);
    const allIndices = zipTableData.map((item: any, index: any) => item.name);

    if (selected) {
      setSelectedCheckboxes(allIndices);
    } else {
      setSelectedCheckboxes([]);
    }
  };

  const handleCheckboxChange = (
    selectedRowKeys: Key[],
    selectedRows: {
      name: string;
      type: string;
      lastModified: string;
      size: string | number;
      storageClass: string;
      key: number;
    }[],
    info: {
      type: 'checkbox' | 'radio';
      checked: boolean;
      selectedRowKeys: Key[];
      nativeEvent: MouseEvent;
    }
  ) => {
    const index = info.selectedRowKeys[0] as number;
    const selectedIndices = [...selectedCheckboxes];

    if (info.checked) {
      selectedIndices.push(index);
    } else {
      const indexToRemove = selectedIndices.indexOf(index);
      if (indexToRemove !== -1) {
        selectedIndices.splice(indexToRemove, 1);
      }
    }

    setSelectedCheckboxes(selectedIndices);
    setSelectAll(selectedIndices.length === zipTableData.length);
  };

  const clearInputValue = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  const filterTableData = (filterText: string) => {
    const filteredData = filteredTableData.filter((item) => item.name.toLowerCase().includes(filterText.toLowerCase()));

    setDataToRender(filteredData);
  };

  const handleDownload = () => {
    if (!filteredTableData || !selectedCheckboxes || !filteredTableData.length || !selectedCheckboxes.length) {
      console.error('Error: filteredTableData or selectedCheckboxes is undefined or empty.');
      return;
    }

    const filesForDownload: string[] = selectedCheckboxes.reduce((acc: any, itemName: string) => {
      const file = filteredTableData.find((item) => item.name === itemName);
      if (file) {
        acc.push(`${file.name}.${file.type || 'unknown'}`);
      } else {
        console.error(`Error: Item '${itemName}' not found in filteredTableData.`);
      }
      return acc;
    }, []);

    if (!filesForDownload.length) {
      console.error('Error: No valid files selected for download.');
      return;
    }

    downloadFileFromS3(credentials.s3ReportsBucket, filesForDownload, 'downloaded_files');
  };

  const handleDownloadItem = (item: any) => {
    let itemName: string = `${item.name}.${item.type || 'unknown'}`;
    downloadOneFileFromS3(credentials.s3ReportsBucket, itemName);
  };

  const handleRefresh = () => {
    clearInputValue();
    setZipTableData([]);
    fetchExcelUploadDev(reportNameFromTab);
  };

  useEffect(() => {
    if (!isInitialLoadDone && zipTableData.length === 0 && isConnectedToAWS) {
      handleRefresh();
      setIsInitialLoadDone(true);
    }
  }, [zipTableData, isInitialLoadDone, isConnectedToAWS]);

  const isDateOlderThan30Minutes = (dateString: string): boolean => {
    const date = new Date(dateString);
    const currentDate = new Date(
      Date.UTC(
        new Date().getUTCFullYear(),
        new Date().getUTCMonth(),
        new Date().getUTCDate(),
        new Date().getUTCHours(),
        new Date().getUTCMinutes(),
        new Date().getUTCSeconds(),
        new Date().getUTCMilliseconds()
      )
    );
    return !((currentDate.getTime() - date.getTime()) / (1000 * 60) > 30);
  };

  const rowClassName = (record: any, index: number): string => {
    if (isDateOlderThan30Minutes(record.rawLastModified)) {
      if (index % 2 === 0) {
        return isDarkMode ? 'older-than-30-minutes-dark_dm' : 'older-than-30-minutes-dark';
      } else {
        return isDarkMode ? 'older-than-30-minutes-light_dm' : 'older-than-30-minutes-light';
      }
    }
    return '';
  };

  const filterData = (filter: any) => {
    clearInputValue();

    if (filter.filterId === currentFilter.filterId) {
      setCurrentFilter('');
      setFilteredTableData(zipTableData);
      setDataToRender(zipTableData);
      return;
    }

    setCurrentFilter(filter);
    const filterNameWithoutExtension = filter.filterId.split('.')[0];
    const filteredData = zipTableData.filter(
      (item: any) => item.name.includes(filterNameWithoutExtension) || item.type.includes(filterNameWithoutExtension)
    );
    setFilteredTableData(filteredData);
    setDataToRender(filteredData);
  };

  const clearFilter = () => {
    filterData({ filterId: '', filterName: '' });
  };

  const columns = [
    {
      title: <Checkbox checked={selectAll} onChange={handleSelectAll} />,
      key: 'checkbox',
      render: (_: any, record: any) => (
        <Checkbox
          checked={selectedCheckboxes.includes(record.name)}
          onChange={(e) =>
            handleCheckboxChange([record.key], [record], {
              type: 'checkbox',
              checked: e.target.checked,
              selectedRowKeys: [record.key],
              nativeEvent: e.nativeEvent as MouseEvent
            })
          }
        />
      )
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a: any, b: any) => a.name.localeCompare(b.name)
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type'
    },
    {
      title: 'Last Modified',
      dataIndex: 'lastModified',
      key: 'lastModified',
      sorter: (a: any, b: any) => {
        const dateA = new Date(a.rawLastModified);
        const dateB = new Date(b.rawLastModified);

        if (dateA < dateB) {
          return -1;
        }
        if (dateA > dateB) {
          return 1;
        }
        return 0;
      },
      defaultSortOrder: 'descend' as SortOrder
    },
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size'
    },
    {
      title: 'Storage Class',
      dataIndex: 'storageClass',
      key: 'storageClass'
    },
    {
      title: '',
      key: 'action',
      render: (_: any, record: any) => (
        <div className="center_download_horizontally">
          <Tooltip title="Download single file">
            <Button type="link" icon={<DownloadOutlined />} onClick={() => handleDownloadItem(record)} />
          </Tooltip>
        </div>
      )
    }
  ];

  const mappedData = dataToRender.map((item, index) => ({
    key: item.name,
    ...item
  }));

  const paginationConfig: any = {
    pageSize: 20,
    position: ['bottomLeft', 'bottomRight'],
    showSizeChanger: false,
    showQuickJumper: false
  };

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <div className="table_wrapper">
      <div className="table_header">
        <div className="filter-wrapper">
          <div className="zip_filter_holder">
            <input
              ref={inputRef}
              type="text"
              placeholder="Type to filter..."
              onChange={(e) => filterTableData(e.target.value)}
            />
            <button>
              <SearchOutlined />
            </button>
          </div>
          <FilterBox filterData={filterData} currentFilter={currentFilter} />
          <Tooltip title={selectedCheckboxes.length > 0 ? 'Download multiple files' : 'No files selected'}>
            <button
              className={`${
                selectedCheckboxes.length > 0 ? '' : 'disabled_button'
              } download_button download_button_width`}
              onClick={handleDownload}
            >
              <DownloadOutlined />
              DOWNLOAD
            </button>
          </Tooltip>
          <Tooltip title="Refresh">
            <button className="download_button refresh_button_width" style={{ width: '60px' }} onClick={handleRefresh}>
              <ReloadOutlined />
            </button>
          </Tooltip>
        </div>
      </div>
      <section className="table-container">
        {currentFilter.filterName && (
          <div className="filter_name_wrapper">
            Selected filter:
            <div className="filter_name">
              <div className="flex">
                <div className="blue_color">{currentFilter.filterName}</div>
                <div className="close_icon" onClick={clearFilter}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="15px" height="15px" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M20.7457 3.32851C20.3552 2.93798 19.722 2.93798 19.3315 3.32851L12.0371 10.6229L4.74275 3.32851C4.35223 2.93798 3.71906 2.93798 3.32854 3.32851C2.93801 3.71903 2.93801 4.3522 3.32854 4.74272L10.6229 12.0371L3.32856 19.3314C2.93803 19.722 2.93803 20.3551 3.32856 20.7457C3.71908 21.1362 4.35225 21.1362 4.74277 20.7457L12.0371 13.4513L19.3315 20.7457C19.722 21.1362 20.3552 21.1362 20.7457 20.7457C21.1362 20.3551 21.1362 19.722 20.7457 19.3315L13.4513 12.0371L20.7457 4.74272C21.1362 4.3522 21.1362 3.71903 20.7457 3.32851Z"
                      fill="#0F0F0F"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        )}
        <Table
          columns={columns}
          dataSource={mappedData}
          pagination={paginationConfig}
          rowClassName={(record, index) => rowClassName(record, index)}
          locale={{
            emptyText: (
              <div className="spin_center">
                <Spin indicator={antIcon} />
              </div>
            )
          }}
        />
      </section>
    </div>
  );
};

export default ReportsTable;
