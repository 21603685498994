import React, { PropsWithChildren, createContext, useEffect, useState } from 'react';
import AWS from 'aws-sdk';
import JSZip from 'jszip';

import { notification } from 'antd';

export type FormType = 'hcfa' | 'ub04' | 'dental';

interface S3Credentials {
  accessKeyId: string;
  secretAccessKey: string;
  sessionToken: string;
  s3UploadBucket: string;
  s3ReportsBucket: string;
  s3OraclePayloadsBucket: string;
}

interface BoardContextType {
  zipTableData: any[];
  setZipTableData(zipTableData: any[]): void;
  zipTableDataOracle: any[];
  setZipTableDataOracle(zipTableDataOracle: any[]): void;
  selectedProject: any | undefined;
  setSelectedProject(setSelectedProject: any | undefined): void;
  s3BucketName: string;
  setS3BucketName(s3BucketName: string): void;
  buckets: any[];
  setBuckets(buckets: any[]): void;
  areRepositoriesFetched: boolean;
  setAreRepositoriesFetched(areRepositoriesFetched: boolean): void;

  credentials: S3Credentials;
  setCredentials(credentials: S3Credentials): void;

  activeKey: string;
  setActiveKey(activeKey: string): void;
  message: string;
  isConnectedToAWS: boolean;
  isAutoRefreshActive: boolean;
  setIsAutoRefreshActive(isAutoRefreshActive: boolean): void;
  setIsConnectedToAWS(isConnectedToAWS: boolean): void;
  checkAwsCredentials(secretAccessKey: any, accessKeyId: any, sessionToken: any, bucketNames: any): any;
  fetchExcelUploadDev(itemName: string | null): boolean;
  fetchOraclePayloadsData(itemName: string | null): boolean;
  downloadFileFromS3(s3BucketName: string, repository: string[], zipFolderName: string): void;
  downloadOneFileFromS3(reportS3BucketName: string, repository: string): void;
  reportWhatToList: string;
  isDarkMode: boolean;
  setIsDarkMode(isDarkMode: boolean): void;
  isErrorActive: boolean;
  setIsErrorActive(isErrorActive: boolean): void;
}

export const BoardContext = createContext({} as BoardContextType);

const REGION = 'us-east-1';

const bucketList: any[] = [];

export const BoardPContext: React.FC<PropsWithChildren> = ({ children }) => {
  const initialCredentials: S3Credentials = {
    accessKeyId: localStorage.getItem('accessKeyId') ?? '',
    secretAccessKey: localStorage.getItem('secretAccessKey') ?? '',
    sessionToken: localStorage.getItem('sessionToken') ?? '',
    s3UploadBucket: localStorage.getItem('s3_upload_bucket') ?? '',
    s3ReportsBucket: localStorage.getItem('s3_reports_bucket') ?? '',
    s3OraclePayloadsBucket: localStorage.getItem('s3_oracle_payloads_bucket') ?? ''
  };

  const [selectedProject, setSelectedProject] = useState<any | undefined>([]);
  const [zipTableData, setZipTableData] = useState<any[]>([]);
  const [zipTableDataOracle, setZipTableDataOracle] = useState<any[]>([]);
  const [s3BucketName, setS3BucketName] = useState<string>(localStorage.getItem('s3_reports_bucket') ?? '');
  const [buckets, setBuckets] = useState<any[]>(bucketList);
  const [areRepositoriesFetched, setAreRepositoriesFetched] = useState<boolean>(true);

  ///AWS login DATA
  const [credentials, setCredentials] = useState<S3Credentials>(initialCredentials);

  const [message, setMessage] = useState('');
  const [isErrorActive, setIsErrorActive] = useState<boolean>(false);
  const [activeKey, setActiveKey] = useState('1');
  const [isAutoRefreshActive, setIsAutoRefreshActive] = useState<boolean>(false);
  const [isConnectedToAWS, setIsConnectedToAWS] = useState<boolean>(false);
  const [reportWhatToList, setReportWhatToList] = useState<string>('');
  const [isDarkMode, setIsDarkMode] = useState<boolean>(false);

  let s3 = new AWS.S3({ region: REGION });

  const awsUpdate = (secretAccessKey: any, accessKeyId: any, sessionToken: any) => {
    AWS.config.update({
      accessKeyId: accessKeyId,
      secretAccessKey: secretAccessKey,
      sessionToken: sessionToken
    });

    s3 = new AWS.S3({ region: REGION });
  };

  awsUpdate(credentials.secretAccessKey, credentials.accessKeyId, credentials.sessionToken);

  const checkAwsCredentials = (accessKeyId: any, secretAccessKey: any, sessionToken: any, bucketNames: any) => {
    setCredentials({
      ...credentials,
      accessKeyId: accessKeyId,
      secretAccessKey: secretAccessKey,
      sessionToken: sessionToken,
      s3UploadBucket: bucketNames['s3_upload_bucket'],
      s3ReportsBucket: bucketNames['s3_reports_bucket'],
      s3OraclePayloadsBucket: bucketNames['s3_oracle_payloads_bucket']
    });

    setReportWhatToList(bucketNames['s3_upload_bucket']);
    setS3BucketName(bucketNames['s3_reports_bucket']);

    awsUpdate(secretAccessKey, accessKeyId, sessionToken);

    const sts = new AWS.STS({
      accessKeyId: accessKeyId,
      secretAccessKey: secretAccessKey,
      sessionToken: sessionToken
    });

    sts.getCallerIdentity({}, (err: any, data: any) => {
      if (err) {
        console.error('Error:', err);
        setMessage(`Error: ${err.message}`);
        setIsErrorActive(true);
        setIsConnectedToAWS(false);

        notification.error({
          message: 'Error',
          description: err.message
        });
      } else {
        setMessage('Connected to AWS');
        setIsErrorActive(true);
        setIsConnectedToAWS(true);
      }
    });

    s3 = new AWS.S3({ region: REGION });
  };

  // useEffect(() => {
  //   fetchExcelUploadDev(null);
  //   fetchOraclePayloadsData(null);
  // }, []);

  // useEffect(() => {
  //   fetchExcelUploadDev(null);
  //   fetchOraclePayloadsData(null);
  // }, [s3BucketName]);

  const fetchExcelUploadDev = (itemName: string | null) => {
    let name = itemName;

    if (itemName === null) {
      return false;
    }

    const bucketName = name || s3BucketName;
    if (!bucketName) {
      return false;
    }

    const paramData = {
      Bucket: bucketName
      // MaxKeys: 10, // Limit the number of objects returned to 10
      // ContinuationToken: continuationToken, // Pass the continuation token if available
    };
    console.log('paramData: ', paramData, 'bucketName: ', bucketName, 'credentials: ', credentials);
    s3.listObjectsV2(paramData, (err: any, data: any) => {
      if (err) {
        console.error('Error fetching objects from S3:', err);
        setZipTableData([]);
        return false;
      } else {
        let processedData = [];
        if (data.Contents) {
          processedData = mapFetchedData(data.Contents || []);
          setZipTableData(processedData || []);
        }
      }
    });
    return true;
  };

  ////////////////////////////////////////////////////////////////////////////
  ////////////////////////////  O R A C L E  /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////
  const fetchOraclePayloadsData = (itemName: string | null) => {
    let name = itemName;

    if (itemName === null) {
      return false;
    }

    const bucketName = name || (buckets.length > 0 ? buckets[0]?.name : null);

    if (!bucketName) {
      return false;
    }

    const paramData = {
      Bucket: bucketName
      // MaxKeys: 10, // Limit the number of objects returned to 10
      // ContinuationToken: continuationToken, // Pass the continuation token if available
    };
    s3.listObjectsV2(paramData, (err: any, data: any) => {
      if (err) {
        console.error('Error fetching objects from S3:', err);
        setZipTableDataOracle([]);
        return false;
      } else {
        let processedData = [];
        if (data.Contents) {
          processedData = mapFetchedDataOracle(data.Contents || []);
          setZipTableDataOracle(processedData || []);
        }
      }
    });
    return true;
  };

  const downloadFileFromS3 = async (s3BucketName: string, fileNames: string[], zipFolderName: string) => {
    notification.info({
      message: 'Download started',
      description: 'Downloading files from S3...'
    });

    const zip = new JSZip();

    // Loop through each file name
    for (const fileName of fileNames) {
      const params = {
        Bucket: s3BucketName,
        Key: fileName
      };

      try {
        const data = await s3.getObject(params).promise();
        if (data.Body instanceof Uint8Array) {
          // Split the file name by slashes to get folder hierarchy
          const parts = fileName.split('/');
          let folder: JSZip = zip; // Initialize folder as JSZip

          // Create folder structure in zip archive
          for (let i = 0; i < parts.length - 1; i++) {
            const folderName = parts[i];
            folder = folder.folder(folderName) || folder; // Ensure folder is always a valid JSZip instance
          }

          // Add file to the appropriate folder in zip archive
          folder.file(parts[parts.length - 1], data.Body);
        } else {
          console.error(`Error downloading file ${fileName} from S3: Data is not of type Uint8Array`);
        }
      } catch (err) {
        console.error(`Error downloading file ${fileName} from S3:`, err);
      }
    }

    // Generate the zip file
    zip
      .generateAsync({ type: 'blob' })
      .then((blob: any) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${zipFolderName}.zip`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      })
      .catch((error: any) => {
        console.error('Error generating zip file:', error);
      });
  };

  // DOWNLOAD ONE FILE
  const downloadOneFileFromS3 = async (reportS3BucketName: string, fileName: string) => {
    notification.info({
      message: 'Download started',
      description: 'Downloading the file from S3...'
    });

    const params = {
      Bucket: reportS3BucketName,
      Key: fileName
    };

    try {
      const data = await s3.getObject(params).promise();
      if (data.Body instanceof Uint8Array) {
        const blob = new Blob([data.Body]);
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      } else {
        console.error(`Error downloading file ${fileName} from S3: Data is not of type Uint8Array`);
      }
    } catch (err) {
      console.error(`Error downloading file ${fileName} from S3:`, err);
    }
  };

  const bytesToKilobytes = (bytes: number): number => {
    return parseFloat((bytes / 1024).toFixed(2));
  };

  const mapFetchedData = (fetchedData: any) => {
    return fetchedData.map((item: any) => {
      const fileName = item.Key.split('.')[0];
      const fileExtension = item.Key.split('.').pop();
      const formattedDate = new Date(item.LastModified).toLocaleString();

      return {
        name: fileName,
        type: fileExtension,
        lastModified: formattedDate,
        rawLastModified: item.LastModified,
        size: bytesToKilobytes(item.Size) + ' KB',
        storageClass: item.StorageClass
      };
    });
  };

  const mapFetchedDataOracle = (fetchedData: any[]) => {
    let result: Record<string, any> = {};

    fetchedData.forEach((item: any) => {
      const parts = item.Key.split('/');
      const mainFolder = parts[0];
      const subFolder = item.Key;

      if (!result[mainFolder]) {
        result[mainFolder] = {
          name: mainFolder,
          type: 'Folder',
          lastModified: null,
          rawLastModified: null,
          size: '-',
          storageClass: '-',
          subFolders: []
        };
      }

      result[mainFolder].subFolders.push({
        name: subFolder,
        type: '',
        lastModified: item.LastModified,
        size: item.Size,
        storageClass: item.StorageClass
      });

      if (item.LastModified > result[mainFolder].lastModified) {
        result[mainFolder].lastModified = item.LastModified;
        result[mainFolder].rawLastModified = item.LastModified;
      }
    });

    Object.values(result).forEach((folder: any) => {
      if (folder.lastModified === null) {
        folder.lastModified = '-';
      } else {
        const options: Intl.DateTimeFormatOptions = {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour12: true, // Use 24-hour format
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit'
        };

        folder.lastModified = new Date(folder.lastModified).toLocaleString('en-US', options);
      }
    });

    return Object.values(result);
  };

  return (
    <BoardContext.Provider
      value={{
        zipTableData,
        setZipTableData,
        zipTableDataOracle,
        setZipTableDataOracle,
        selectedProject,
        setSelectedProject,
        s3BucketName,
        setS3BucketName,
        buckets,
        setBuckets,
        areRepositoriesFetched,
        setAreRepositoriesFetched,

        credentials,
        setCredentials,

        activeKey,
        setActiveKey,
        message,
        isConnectedToAWS,
        isAutoRefreshActive,
        setIsAutoRefreshActive,
        setIsConnectedToAWS,
        checkAwsCredentials,
        fetchExcelUploadDev,
        fetchOraclePayloadsData,
        downloadFileFromS3,
        downloadOneFileFromS3,
        reportWhatToList,
        isDarkMode,
        setIsDarkMode,
        isErrorActive,
        setIsErrorActive
      }}
    >
      {children}
    </BoardContext.Provider>
  );
};
