import React from 'react';
import { BoardPContext } from './context/BoardContextProvider';
import { AppHeader } from 'components/app/AppHeader';
import { AppRoutes } from 'components/app/AppRoutes';
import { ConfigProvider } from 'antd';

function App(): JSX.Element {
  const isURLContainLanding = window.location.href.includes('landing');

  return (
    <ConfigProvider prefixCls={`ant`}>
      <BoardPContext>
        <div className="app-container">
          <AppHeader />
          <div className={`app__content`} style={{ height: `93vh`, overflowY: 'scroll' }}>
            <AppRoutes />
          </div>
        </div>
      </BoardPContext>
    </ConfigProvider>
  );
}
export default App;
